export const images = {

  // summaryImages: {
  //   // totalBook: require("./images/perspective_matte-385-128x128.png"),
  //   sold: require("./images/perspective_matte-67-128x128.png"),
  //   cancel: require("./images/perspective_matte-15-128x128.png")
  // },
  // userProfile: require("./images/7309681.jpg"),
  cartoonAvatar: require("./images/cartoonAvatarCircular.png"),



  // profilePicture: require("./images/roundedProfile.png"),
  calendar: require("./images/calendar.png"),

  blondeGirl: require("./images/girlImage.jpg"),
  tiltedPhone: require("./images/iphoneSwipeMateRotated.png")
};